<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  city: City
}

defineProps<Props>()

const { icons } = useDesign()

defineOptions({
  name: 'SearchPageResultCity',
})
</script>

<template>
  <CityLink
    :city
    class="flex min-h-16 break-inside-avoid items-center space-x-4 rounded-lg p-2 hover:underline"
  >
    <Icon :name="icons.MAP_MARKER_OUTLINE" class="size-6 fill-current" />

    <div class="text-sm leading-tight sm:text-base">
      <div v-text="city.name" />
      <div
        v-if="city.postcodes"
        class="text-sm"
        v-text="formatPostcodes(city)"
      />
    </div>
  </CityLink>
</template>
