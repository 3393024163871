<template>
  <div data-catalog-persons class="space-y-6">
    <FiltersWrapper>
      <FiltersSearch
        :placeholder="t('searchPlaceholder')"
        :collapse="false"
        :url-prefix
      />
    </FiltersWrapper>

    <PersonCardsVertical
      :persons
      :paginator-info
      :url-prefix
      :items-per-page
      :next
      :prev
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  urlPrefix?: string
  itemsPerPage?: number
}

const { urlPrefix, itemsPerPage } = defineProps<Props>()
const { t } = useI18n()

const { persons, paginatorInfo, next, prev } = await usePersons({
  fetchOperation: 'FetchPersons',
  urlPrefix,
  preselected: {},
  first: itemsPerPage,
})

defineOptions({
  name: 'CatalogPersons',
})
</script>

<i18n>
de:
  searchPlaceholder: "Person suchen"
es:
  searchPlaceholder: "Buscar persona"
</i18n>
